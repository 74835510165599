/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                //Jquery Animation for Different Sections
                // GENERAL SETTING
                window.sr = ScrollReveal({
                 reset: true,
                 mobile: true
                 });

                 // Custom Settings
                 sr.reveal('.slide-in-from-left', {
                 opacity: 0,
                 scale: 1,
                 origin: 'left',
                 distance: '100%',
                 duration: 500,
                 delay: 0,
                 easing: 'linear',
                 viewFactor: 0.4
                 });

                 sr.reveal('.slide-in-from-right', {
                 opacity: 0,
                 scale: 1,
                 origin: 'right',
                 distance: '100%',
                 duration: 500,
                 delay: 0,
                 easing: 'linear',
                 viewFactor: 0.4
                 });
                 sr.reveal('.slide-in-from-top', {
                 opacity: 0,
                 scale: 1,
                 origin: 'top',
                 distance: '100%',
                 duration: 500,
                 delay: 0,
                 easing: 'linear',
                 viewFactor: 0.4
                 });
                 sr.reveal('.slide-in-from-bottom', {
                 opacity: 0,
                 scale: 1,
                 origin: 'bottom',
                 distance: '10%',
                 duration: 500,
                 delay: 0,
                 easing: 'linear',
                 viewFactor: 0.1,
                 afterReveal: function(domEl) {console.log('after revealed');},
                 afterReset: function (domEl) {console.log('after reset');}
                 });


                // JavaScript to be fired on all pages
                //Siderbar Menu

                $(document).ready(function () {
                    $('#sidebar ul li a').addClass('popup-smoothScroll');
                    $('.normal-menu .menu-item-type-custom a').addClass('smoothScroll');
                    $('.icon-one').click(function () {
                        $('.icon-one').toggleClass('active-one');
                        $('#sidebar').toggleClass('active');
                        $('body').toggleClass('overflow-toggler');
                        //$('.wrap').css('top', '100vh');

                    });

                });

                //Popup Menu Smooth Scrolling
                $(function () {
                    // This will select everything with the class smoothScroll
                    // This should prevent problems with carousel, scrollspy, etc...
                    $('.popup-smoothScroll').click(function () {
                        $('.icon-one').toggleClass('active-one');
                        $('#sidebar').toggleClass('active');
                        $('body').toggleClass('overflow-toggler');
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {
                                $('html,body').stop().animate({
                                    scrollTop: target.offset().top
                                }, 1000); // The number here represents the speed of the scroll in milliseconds
                                //console.log(target.offset().top);
                                return false;
                            }
                        }

                    });

                });

                //Smooth Scrolling For Normal Menu Points
                $(function () {
                    // This will select everything with the class smoothScroll
                    // This should prevent problems with carousel, scrollspy, etc...
                    $('.smoothScroll').click(function () {
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {
                                $('html,body').stop().animate({
                                    scrollTop: target.offset().top
                                }, 1000); // The number here represents the speed of the scroll in milliseconds
                                //console.log(target.offset().top);
                                return false;
                            }
                        }

                    });

                });
                //Smooth Scrolling for DOWN Arrow
                $(function () {
                    // This will select everything with the class smoothScroll
                    // This should prevent problems with carousel, scrollspy, etc...
                    $('.scroll-down').click(function () {
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {
                                $('html,body').stop().animate({
                                    scrollTop: target.offset().top
                                }, 1000); // The number here represents the speed of the scroll in milliseconds
                                //console.log(target.offset().top);
                                return false;
                            }
                        }

                    });

                });

                ////Checking Content-Body Scroll Top Position

                    $(function(){
                        var CurrentScroll = 0;
                        $(window).scroll(function(event){
                            if($(window).width() > 991){
                                content_body_distance = $('#content-body').offset().top - $(window).scrollTop();
                                var NextScroll = $(this).scrollTop();
                                //console.log(content_body_distance);
                                if (NextScroll > CurrentScroll){
                                    //write the codes related to down-ward scrolling here
                                    if(content_body_distance < 50 ){
                                        $('.icon-one').fadeIn(200);
                                    }
                                }
                                else {
                                    if(content_body_distance > 50 ){
                                        $('.icon-one').fadeOut(200);
                                    }
                                }

                                CurrentScroll = NextScroll;  //Updates current scroll position
                            }
                        });
                    });

               ///Div Overlaps and Change Color of Burger Menu
                /*function collision($fixed, $moving) {
                    var x1 = $fixed.offset().left;
                    var y1 = $fixed.offset().top;
                    var h1 = $fixed.outerHeight(true);
                    var w1 = $fixed.outerWidth(true);
                    var b1 = y1 + h1;
                    var r1 = x1 + w1;
                    var x2 = $moving.offset().left;
                    var y2 = $moving.offset().top;
                    var h2 = $moving.outerHeight(true);
                    var w2 = $moving.outerWidth(true);
                    var b2 = y2 + h2;
                    var r2 = x2 + w2;

                    if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;
                    return true;
                }

                $(window).scroll(function() {
                    var all = $(".two_column_grid");
                    for (var i = 0; i < all.length; i++) {
                        if (collision($(".icon-one"), all.eq(i))) {
                            $('.icon-one .hamburger').css('background', 'white');
                            $( "<style> .hamburger:after { background: white; }</style>" ).appendTo( "head" );
                            $( "<style> .hamburger:before { background: white; }</style>" ).appendTo( "head" );
                            break; //no need to test the others !
                        } else {
                            $('.icon-one .hamburger').css('background', '#767676');
                            $( "<style> .hamburger:after { background: #767676; }</style>" ).appendTo( "head" );
                            $( "<style> .hamburger:before { background: #767676; }</style>" ).appendTo( "head" );
                        }
                    }
                });*/

                $(document).ready(function () {
                    var width = $(window).width();
                    if(width < 576){
                        $('.lightbox-modal-tab').removeAttr("data-toggle");
                    }
                });



            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
